export default class ApiRequests {}

ApiRequests.user = {
  getAccessRights: ({ client }, entityType, entityId) => {
    return client.get("/api/knowledgebase/access/" + entityType + "/" + entityId);
  },
  saveAccessRights: ({ client }, entityType, entityId, rightsList) => {
    return client.post(
      "/api/knowledgebase/access/" + entityType + "/" + entityId,
      rightsList
    );
  },
  searchUser: ({ client }, { query, page = 1 }) =>
    client.post("/api/knowledgebase/search/user", {
      query: query,
      page: page,
    }),
  getDepartmentsTree: ({ client }) => client.get("/api/departments/"),
  getRootDepartmentsTree: ({ client }, data) =>
    client.get("/api/departments/root/", { params: data }),
};

ApiRequests.article = {
  suggest: ({ client }, data) => client.post("/api/knowledgebase/article/suggest", data),
  archive: ({ client }) => client.get("/api/knowledgebase/article/archive"),
  grays: ({ client }) => client.get("/api/knowledgebase/article/grays"),
  create: ({ client }, article) => client.post("/api/knowledgebase/article/", article),
  update: ({ client }, id, article) =>
    client.post("/api/knowledgebase/article/" + id, article),
  delete: ({ client }, articleId) => client.delete("/api/knowledgebase/article/" + articleId),
  get: ({ client }, id) => client.get("/api/knowledgebase/article/" + id),
  getWithHighlight: ({ client }, id, query) =>
    client.get("/api/knowledgebase/article/highlight/" + id, {
      params: { query: query.text, strict: query.strict },
    }),
  getHistory: ({ client }, id) => client.get("/api/knowledgebase/article/history/" + id),
  restoreVersion: ({ client }, articleId, versionId) =>
    client.post("/api/knowledgebase/article/restore/" + articleId + "/" + versionId),
  likeArticle: ({ client }, articleId) =>
    client.post("/api/knowledgebase/article/like/" + articleId),
  unLikeArticle: ({ client }, articleId) =>
    client.post("/api/knowledgebase/article/unlike/" + articleId),
  saveComment: ({ client }, comment) => {
    if (comment.parent_id > 0)
      return client.post("/api/knowledgebase/comments/" + comment.parent_id, comment);
    else return client.post("/api/knowledgebase/comments", comment);
  },
  deleteComment: ({ client }, commentId) => client.delete("/api/knowledgebase/comments/" + commentId),
  snippets: ({ client }, type) => client.get("/api/knowledgebase/article/snippets/" + type),
  articles: ({ client }, data) => client.post("/api/knowledgebase/article/get-by-ids", data),
  read: {
    load: ({ client }) => client.get("/api/knowledgebase/article/read/list"),
    setRead: ({ client }, data) => client.post("/api/knowledgebase/article/set-read", data),
  },
  unread: {
    load: ({ client }) => client.get("/api/knowledgebase/article/unread/list"),
  },
  mostLiked: ({ client }) => client.get("api/knowledgebase/article/main-page/most-liked"),
  lastSeen: ({ client }) => client.get("api/knowledgebase/article/main-page/last-seen"),
  lastUpdate: ({ client }) => client.get("/api/knowledgebase/article/main-page/last-updated"),
  setSubscription: ({ client }, data) =>
    client.post(
      "/api/knowledgebase/article/" + data.article_id + "/subscription/" + data.user_id
    ),
};

ApiRequests.tree = {
  full: ({ client }) => client.get("/api/knowledgebase/tree/full"),
  sections_list: ({ client }) => client.get("/api/knowledgebase/section"),
};
ApiRequests.section = {
  save: ({ client }, section) => {
    if (section.id !== false)
      return client.patch("/api/knowledgebase/section/" + section.id, section);
    else return client.post("/api/knowledgebase/section/", section);
  },
  delete: ({ client }, sectionId) => client.delete("/api/knowledgebase/section/" + sectionId),
  get: ({ client }, id) => client.get("/api/knowledgebase/section/" + (id || "root")),
  getWithHighlight: ({ client }, id, query) =>
    client.get("/api/knowledgebase/section/highlight/" + id, {
      params: { query: query.text, strict: query.strict },
    }),
  getTop: ({ client }) => client.get("api/knowledgebase/section/get-top"),
  articles: ({ client }, id, data) =>
    client.get("/api/knowledgebase/section/" + id + "/articles/", {
      params: { page: data.page, per_page: data.per_page },
    }),
};

ApiRequests.access = {
  get: ({ client }, { id, type }) =>
    client.post("/api/acl/access/get", { entity: { id, type }, group: true }),
  set: ({ client }, { id, type, define, forbid }) =>
    client.post("/api/acl/access/set", {
      entity: { id, type },
      define,
      forbid,
      group: true,
    }),
};

ApiRequests.favorite = {
  list: ({ client }) => client.get("/api/knowledgebase/favorite/"),
  set: ({ client }, entity, id) =>
    client.post("/api/knowledgebase/" + entity + "/favorite/" + id),
  remove: ({ client }, entity, id) =>
    client.post("/api/knowledgebase/" + entity + "/unfavorite/" + id),
};

ApiRequests.search = {
  full: ({ client }, search) =>
    client.get(encodeURI("/api/knowledgebase/search?per_page=3&search=" + search)),
  article: (
    { client },
    { query, type, workspace, sections, strict, page = 1, tags }
  ) =>
    client.get("/api/knowledgebase/search/article", {
      params: {
        type: type,
        query: query,
        workspace: workspace,
        sections: sections,
        strict: strict,
        page: page,
        tags: tags,
      },
    }),
  section: ({ client }, { query, workspace, sections, strict, page = 1 }) =>
    client.get("/api/knowledgebase/search/section", {
      params: {
        query: query,
        workspace: workspace,
        sections: sections,
        strict: strict,
        page: page,
      },
    }),
  survey: ({ client }, { query, workspace, strict, page = 1 }) =>
    client.get("/api/knowledgebase/search/survey", {
      params: {
        query: query,
        workspace: workspace,
        strict: strict,
        page: page,
      },
    }),
  media: ({ client }, { query, workspace, strict, page = 1 }) =>
    client.get("/api/knowledgebase/search/media", {
      params: {
        query: query,
        workspace: workspace,
        strict: strict,
        page: page,
      },
    }),
  articleTable: ({ client }, request) =>
    client.post("/api/knowledgebase/article/search/table/" + request.id, request),
  department: ({ client }, { query, page = 1 }) =>
    client.get("/api/departments/search", {
      params: { query: query, page: page },
    }),
  position: ({ client }, { query, page = 1 }) =>
    client.get("/api/positions/search", {
      params: { query: query, page: page },
    }),
  competence: ({ client }, { query, page = 1 }) =>
    client.get("/api/competence/search", {
      params: { query: query, page: page },
    }),
  sectionsList: ({ client }, { query, workspace, page = 1 }) =>
    client.get("/api/knowledgebase/search/sections-list", {
      params: { query: query, workspace: workspace, page: page },
    }),
  termin: ({ client }, data) =>
    client.get("/api/knowledgebase/search/termin", {
      params: { query: data },
    }),
};

ApiRequests.termin = {
  save: ({ client }, termin, description) =>
    client.post("/api/knowledgebase/termin/save/", { title: termin, description: description }),
  delete: ({ client }, id) =>
    client.post("/api/knowledgebase/termin/delete/" + id),
  update: ({ client }, termin) =>
      client.post("/api/knowledgebase/termin/update/" + termin.id , termin),
  getList: ({ client }) =>
      client.get("/api/knowledgebase/termin/get-list/"),
};

ApiRequests.tag = {
  search: ({ client }, query, exclude) =>
    client.post(encodeURI("/api/knowledgebase/tag/search/" + query), {
      exclude: exclude,
    }),
  getModels: ({ client }, tag) =>
    client.get(encodeURI("/api/knowledgebase/tag/content/" + tag)),
};

ApiRequests.share = {
  generateB24: function ({ client }, url = null) {
    const params = url === null ? {} : { url };
    return client.get("/auth/bitrix/placement/url/", { params });
  },
  shareB24: function ({ client }, {type, id}) {
    return client.get("/api/knowledgebase/" + type + "/" + id + "/b24");
  },
};

ApiRequests.workspaces = {
  indexAll: ({ client }) => client.get("/api/workspaces/all"),
  indexUser: ({ client }) => client.get("/api/workspaces/user"),
  create: ({ client }, data) => client.post("/api/workspaces/create", data),
  del: ({ client }, data) => client.post("/api/workspaces/delete", data),
  edit: ({ client }, workspace) =>
    client.patch("/api/workspaces/" + workspace.id, workspace),
  switch: ({ client }, id) => client.patch("/api/workspaces/switch/" + id),
};

ApiRequests.tasks = {
  createTemplate: ({ client }, id, data) =>
    client.post("/api/knowledgebase/tasks/create-template/" + id, data),
  rerunTasks: ({ client }, id, data) =>
    client.post("/api/knowledgebase/tasks/rerun-tasks/" + id, data),
  hasTemplate: ({ client }, id) =>
    client.get("/api/knowledgebase/tasks/" + id + "/has-template/"),
  getTemplate: ({ client }, id) =>
    client.get("/api/knowledgebase/tasks/" + id + "/get-template/"),
  getDepartmentUsers: ({ client }, id) =>
    client.get("/api/departments/" + id + "/users/"),
  getUser: ({ client }, id) =>
    client.get("/api/knowledgebase/tasks/" + id + "/get-user/"),
  deleteUserTask: ({ client }, data) =>
    client.post("/api/knowledgebase/tasks/user/delete/", data),
  deleteDepartmentTask: ({ client }, data) =>
    client.post("/api/knowledgebase/tasks/department/delete/", data),
  deletePositionTask: ({ client }, data) =>
    client.post("/api/knowledgebase/tasks/position/delete/", data),
  getPositionUsers: ({ client }, id) =>
    client.get("/api/positions/" + id + "/users/"),
  getTemplateUsers: ({ client }, id) =>
    client.get("/api/knowledgebase/tasks/" + id + "/get-template-users/"),
  load: ({ client }) => client.get("/api/knowledgebase/tasks/articles-to-familiarize"),
  close: ({ client }, task_id) => client.patch("/api/knowledgebase/tasks/close/" + task_id),
  unclose: ({ client }, task_id) =>
    client.patch("/api/knowledgebase/tasks/unclose/" + task_id),
  load_users_tasks: ({ client }, data) => { 
    return client.post("/api/knowledgebase/tasks/load_users_tasks_for_article", data);
  }
};

ApiRequests.mass = {
  destroy: ({ client }, data) => client.delete("/api/knowledgebase/mass/delete", { data }),
  move: ({ client }, data) => client.post("/api/knowledgebase/mass/move", data),
  favourite: ({ client }, data) => client.post("/api/knowledgebase/mass/favourite", data),
};

ApiRequests.support = {
  getSupporters: ({ client }) => client.get("/api/knowledgebase/support/supporters"),
  setSupporters: ({ client }, supporters) =>
    client.post("/api/knowledgebase/support/supporters", { supporters: supporters }),
  sendMessage: ({ client }, message) =>
    client.post("/api/knowledgebase/support/message", { message: message }),
};

ApiRequests.kb_menu = {
  loadChildrenOptions: ({ client }, { id, type, workspace }) =>
    client.get("/api/knowledgebase/kb-menu/load-children/" + id, {
      params: { type: type, workspace: workspace },
    }),
  buildAncestorsTree: ({ client }, id, type) =>
    client.get("/api/knowledgebase/kb-menu/ancestors-tree/" + type + "/" + id),
};

ApiRequests.sorting = {
  sections: ({ client }, data) =>
    client.post("/api/knowledgebase/sections/update-sorting", data),
  articles: ({ client }, data) =>
    client.post("/api/knowledgebase/articles/update-sorting", data),
};

ApiRequests.analytics = {
  mostPopularAuthors: ({ client }, data) =>
    client.post("/api/knowledgebase/analytics/popular_authors", data),
  articlesPerPeriod: ({ client }, data) =>
    client.post("/api/knowledgebase/analytics/articles_per_period", data),
  mostViewedArticles: ({ client }, data) =>
    client.post("/api/knowledgebase/analytics/articles", data),
  authorizationsPerPeriod: ({ client }, data) =>
    client.post("/api/knowledgebase/analytics/authorizations_count", data),
};

ApiRequests.users = {
  generateNewPassword: ({ client, settings }, data) =>
    client.post("/api/users/notify/password", data),
  delete: ({ client, settings }, ids) =>
    client.post("/api/users/delete", { users: ids }),
};

ApiRequests.positions = {
  getAll: ({ client, settings }) =>
    client.get("/api/positions", null),
};
ApiRequests.trashed = {
  get: ({ client }, data) => client.get("api/knowledgebase/trashed/list", { params: data }),
  restore: ({ client }, data) =>
    client.post("api/knowledgebase/trashed/restore", data),
};
ApiRequests.wizard = {
  surveys:
      {
        getSurveys: ({client}) => client.get('/api/wizard/surveys'),
        getSurvey: ({client}, id) => client.get('/api/wizard/surveys/' + id),
        addSurvey: ({client}, data) => client.post('/api/wizard/surveys', data),
        updateSurvey: ({client}, id, data) => client.post('/api/wizard/surveys/' + id, data),
        deleteSurvey: ({client}, surveyId) => client.delete('/api/wizard/surveys/' + surveyId),
        updateSorting: ({client}, data) => client.post('/api/wizard/surveys/update-sorting', data),
        addAction: ({client}, {answer_id, link_type, link_id}) =>
            client.post('/api/wizard/actions', {answer_id, link_type, link_id}),
        deleteAction: ({client}, id) => client.delete('/api/wizard/actions/' + id),
        getAction: ({client}, id) => client.get('/api/wizard/actions/' + id),
        updateAction: ({client}, id) => client.get('/api/wizard/actions' + id),
        getWizardSettings: ({client}, service) => client.get('/api/workspaces/get-workspace-settings?service=' + service),
        setWizardScriptsView: ({client}, data) => client.post('/api/workspaces/set-workspace-settings', data),
      },
  questions:
      {
        getBySurveyId: ({ client }, surveyId) => client.get('/api/wizard/surveys/' + surveyId + '/questions'),
        addAnswer: ({ client },  { type, sort, question_id, text }) =>
            client.post('/api/questions/answers', { type, sort, question_id, text }),
        getAnswer: ({ client }, id) => client.get('/api/questions/answers' + id),
        deleteAnswer: ({ client }, id) => client.delete('/api/questions/answers' + id),
        updateAnswer: ({ client }, id) => client.put('/api/questions/answers/' + id),
        // aggr_type:hh_survey
        // aggr_id:id_survey
        // type:'text'
        // text:'String',
        // sort:1
        createQuestions: ({ client }, { surveyId, questions }) => {
          return client.post('/api/wizard/questions/create-with-answers', {
            aggregator_id: surveyId,
            aggregator_type: 'hh_survey',
            questions: questions,
          });
        },
        updateQuestions: ({ client }, { surveyId, questions }) => {
          questions.map(question => {
            delete question.aggregator_type;
            delete question.aggregator_id;
            delete question.created_at;
            delete question.updated_at;
          });
          return client.post('/api/wizard/questions/edit-and-create', {
            aggregator_id: parseInt(surveyId),
            aggregator_type: 'hh_survey',
            questions: questions,
          });
        },
        addQuestion: ({ client }, { type, aggregator_id, text }) =>
            client.post('/api/questions/questions/', {
              aggregator_type: 'hh_survey',
              aggregator_id,
              sort: 1,
              text,
              type,
            }),
        deleteQuestion: ({ client }, questionId) => client.delete('/api/questions/questions/' + questionId),
      },
  sections:
      {
        getRoot: ({ client }) => client.get('/api/wizard/section'),
        get: ({ client }, id) => client.get('/api/wizard/section/' + id),
        save: ({ client }, section) => section.has('id') ? client.post('/api/wizard/section/' + section.get('id'), section) : client.post('/api/wizard/section/', section),
        delete: ({ client }, id) => client.delete('/api/wizard/section/' + id),
      }
};