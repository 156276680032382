/**
 * Routes of service
 *
 * All routes must be use layout component on first level (with path "/")
 * Root of service index component must have redirect to service index page (it may any name - not only index)
 */

export default [
  {
    path: "/",
    redirect: { name: "home" },
    name: "root",
    components: {
      default: () => import("@/view/layout/Layout"),
    },
    children: [
      {
        path: "/",
        components: {
          default: () => import("@/view/layout/templates/Empty"),
          search: () => import("./components/Searchbar.vue"),
          "title-actions": () => import("./components/Subheader/ActionsButton.vue"),
        },
        children: [
          {
            path: "/",
            name: "home",
            component: () => import("./views/Main.vue"),
          },
          {
            path: "/section",
            name: "section.index",
            component: () => import("./views/SectionView.vue"),
            props: true,
          },
          {
            path: "/section/view/:id",
            name: "section.view",
            component: () => import("./views/SectionView.vue"),
            props: true,
          },
        ],
      },
      {
        path: "/analytics",
        components: {
          default: () => import("@/view/layout/templates/Analitics"),
        },
        children: [
          {
            path: "/",
            name: "analytics",
            components: {
              "analitics": () => import("./components/Analitics/AnaliticsBox.vue"),
            },
            props: true
          },
        ]
      },
      {
        path: "/",
        components: {
          default: () => import("@/view/layout/templates/Empty"),
          search: () => import("./components/Searchbar.vue"),
        },
        children: [
          {
            path: "/",
            name: "search",
            component: () => import("./components/Searchbar"),
          },
          {
            path: "/favorites",
            name: "favorites",
            component: () => import("./views/Favorites.vue"),
          },
          {
            path: "/archive",
            name: "archive",
            component: () => import("./views/Archive.vue"),
          },
          {
            path: "/snippets",
            name: "snippets",
            component: () => import("./views/SnippetsView.vue"),
          },
          {
            path: "/drafts",
            name: "drafts",
            component: () => import("./views/Drafts.vue"),
          },
          {
            path: "/trash",
            name: "trash",
            component: () => import("./views/Trash.vue"),
          },
          {
            path: "/support",
            name: "support",
            component: () => import("./views/Support.vue"),
          },
          {
            path: "/help",
            name: "help",
            component: () => import("./views/Help.vue"),
          },
          {
            path: "/search",
            name: "search.full",
            component: () => import("./views/FullSearch.vue"),
            props: true
          },
          {
            path: "/section/new",
            name: "section.new",
            component: () => import("./views/SectionEdit.vue"),
            props: true,
          },
          {
            path: "/section/subsection/:id",
            name: "section.subsection.new",
            component: () => import("./views/SectionEdit.vue"),
          },
          {
            path: "/section/edit/:id",
            name: "section.edit",
            component: () => import("./views/SectionEdit.vue"),
          },
          {
            path: "/articles",
            name: "article.list",
            component: () => import("./views/SectionView.vue"),
            props: true,
          },
          {
            path: "/article/new",
            name: "article.new",
            component: () => import("./views/ArticleEdit.vue"),
            props: true,
          },
          {
            path: "/article/edit/:id",
            name: "article.edit",
            component: () => import("./views/ArticleEdit.vue"),
            props: true,
          },
          {
            path: "/article/view/:id",
            name: "article.view",
            component: () => import("./views/ArticleView.vue"),
            props: (route) => ({
              type: "article",
              id: route.params.id,
            }),
          },
          {
            path: "/article/history/:id",
            name: "article.history",
            component: () => import("./views/ArticleHistory.vue"),
          },

          /* Snippets */
          {
            path: "/snippet/informer/new",
            name: "snippet.informer.new",
            component: () => import("./views/ArticleEdit.vue"),
            props: (route) => ({ type: "snippet.informer" }),
          },
          {
            path: "/snippet/informer/view/:id",
            name: "snippet.informer.view",
            component: () => import("./views/ArticleView.vue"),
            props: (route) => ({
              type: "snippet.informer",
              id: route.params.id,
            }),
          },
          {
            path: "/snippet/informer/edit/:id",
            name: "snippet.informer.edit",
            component: () => import("./views/ArticleEdit.vue"),
            props: (route) => ({
              type: "snippet.informer",
              id: route.params.id,
            }),
          },

          {
            path: "/snippet/content/new",
            name: "snippet.content.new",
            component: () => import("./views/ArticleEdit.vue"),
            props: (route) => ({ type: "snippet.content" }),
          },
          {
            path: "/snippet/content/view/:id",
            name: "snippet.content.view",
            component: () => import("./views/ArticleView.vue"),
            props: (route) => ({
              type: "snippet.content",
              id: route.params.id,
            }),
          },
          {
            path: "/snippet/content/edit/:id",
            name: "snippet.content.edit",
            component: () => import("./views/ArticleEdit.vue"),
            props: (route) => ({
              type: "snippet.content",
              id: route.params.id,
            }),
          },
          /* End: Snippets */
          {
            path: "/tag/view/:tag",
            name: "tag.view",
            component: () => import("./views/TagView.vue"),
          },
          {
            path: "/bitrix/v16sync/",
            name: "bitrix.v16sync",
            component: () => import("./views/Bitrix/v16sync.vue"),
          },

          {
            path: "/administrator/",
            name: "admin.navigation",
            component: () => import("./views/AdministratorNavigation"),
          },
          {
            path: "/manage-workspaces/",
            name: "workspaces.manage",
            component: () => import("./views/WorkspacesManage"),
          },
          {
            path: "/familiarize-articles",
            name: "articles.familiarize",
            component: () => import("./views/FamiliarizeArticles"),
          },
          {
            path: "/new-articles",
            name: "articles.new",
            component: () => import("./views/NewArticlesList"),
          },
          {
            path: "/glossary",
            name: "glossary",
            component: () => import("./views/Glossary.vue"),
          },
        ],
      },
      {
        path: "/wizard",
        components: {
          default: () => import("./layouts/WizardLayout"),
          search: () => import("./components/Searchbar.vue"),
          "title-actions": () => import("./components/Subheader/ActionsButton"),
        },
        children: [
          {
            path: '/wizard/section/view/:id?',
            name: 'wizard.section.view',
            component: () => import('./views/Wizard/SectionView.vue'),
          },
          {
            path: '/wizard/surveys',
            name: 'wizard.surveys',
            component: () => import('./views/Wizard/SurveyList.vue'),
          },
        ],
      },
      {
        path: "/wizard",
        components: {
          default: () => import("./layouts/WizardLayout"),
          search: () => import("./components/Searchbar.vue"),
        },
        children: [
          {
            path: '/wizard/section/new',
            name: 'wizard.section.new',
            component: () => import('./views/Wizard/SectionEdit.vue'),
          },
          {
            path: '/wizard/section/edit/:id',
            name: 'wizard.section.edit',
            component: () => import('./views/Wizard/SectionEdit.vue'),
          },
          {
            path: '/wizard/survey/new',
            name: 'wizard.survey.new',
            component: () => import('./views/Wizard/SurveyCreate.vue'),
          },
          {
            path: '/wizard/survey/edit/:id',
            name: 'wizard.survey.edit',
            component: () => import('./views/Wizard/SurveyEdit.vue'),
          },
          {
            path: '/wizard/questions/:id',
            name: 'wizard.questions.new',
            component: () => import('./views/Wizard/QuestionsNew.vue'),
          },
          {
            path: '/wizard/survey/questions/edit/:id',
            name: 'wizard.questions.edit',
            component: () => import('./views/Wizard/QuestionsEdit.vue'),
          },
          {
            path: '/wizard/survey/:id',
            name: 'wizard.survey.view',
            component: () => import('./views/Wizard/SurveyView.vue'),
          },
        ],
      },
    ],
  },
];
