import Vue from "vue";
import comments from "./comments";
import moment from "moment";
import LocalStorage from "../services/LocalStorage";
const helpers = {
  GET_ARTICLE_TYPE(data, full = false) {
    let type = data["additions.type"] || "article";

    const map = {
      article: "article",
      "snippet.content": "content",
      "snippet.informer": "informer",
    };
    if (map[type] === undefined) type = "article";
    return full ? type : map[type];
  },
  GET_EMPTY_ARTICLE() {
    return {};
  },
  GET_EMPTY_SECTION() {
    return {
      is_full: false,
      articles: {},
      children: {},
    };
  },
};

const init_state = {
  sections: {
    0: {
      ...helpers.GET_EMPTY_SECTION(),
      id: 0,
      is_full: false,
      lft: -Infinity,
      rgt: Infinity,
    },
  },
  articles: {},
  // For all article_id: show
  type: {
    article: {},
    content: {},
    informer: {},
  },
  archive: {},
  gray: {},
  favorite: {
    section: {},
    article: {},
    content: {},
    informer: {},
  },
  unreadArticles: [],
  unreadArticlesLoaded: false,
  loaded: false,
  initialized: false,
  favoriteArticlesLoaded: false,
};

const getters = {
  sections(state) {
    return state.sections;
  },
  articles(state) {
    return Object.keys(state.type.article)
      .filter(
        (article_id) => !state.gray[article_id] && !state.archive[article_id]
      )
      .map((article_id) => state.articles[article_id]);
  },
  archives(state) {
    return Object.keys(state.type.article)
      .filter((article_id) => state.archive[article_id] || false)
      .map((article_id) => state.articles[article_id]);
  },
  grays(state) {
    return Object.keys(state.type.article)
      .filter((article_id) => state.gray[article_id])
      .map((article_id) => state.articles[article_id]);
  },
  informers(state) {
    return Object.keys(state.type.informer)
      .filter(
        (article_id) => !state.gray[article_id] && !state.archive[article_id]
      )
      .map((article_id) => state.articles[article_id]);
  },
  contents(state) {
    return Object.keys(state.type.content)
      .filter(
        (article_id) => !state.gray[article_id] && !state.archive[article_id]
      )
      .map((article_id) => state.articles[article_id]);
  },

  informer(state) {
    return (informer_id) => {
      const article =
        state.articles[informer_id] || helpers.GET_EMPTY_ARTICLE();
      return state.type.informer[informer_id]
        ? article
        : helpers.GET_EMPTY_ARTICLE();
    };
  },
  content(state) {
    return (content_id) => {
      const article = state.articles[content_id] || helpers.GET_EMPTY_ARTICLE();
      return state.type.content[content_id]
        ? article
        : helpers.GET_EMPTY_ARTICLE();
    };
  },
  article(state) {
    return (article_id, with_archive = false) => {
      const article = state.articles[article_id] || helpers.GET_EMPTY_ARTICLE();
      return with_archive || !state.archive[article_id]
        ? article
        : helpers.GET_EMPTY_ARTICLE();
    };
  },
  section(state) {
    return (section_id) => {
      return state.sections[section_id] || helpers.GET_EMPTY_SECTION();
    };
  },
  favorites(state) {
    const favList = [];
    for (const id in state.favorite.section) {
      if (state.favorite.section[id]) favList.push(state.sections[id]);
    }
    for (const id in state.favorite.article) {
      if (state.favorite.article[id] && !state.archive[id])
        favList.push(state.articles[id]);
    }
    return favList;
  },
  favoriteArticles(state) {
    const favoriteArticlesList = [];
    for (const id in state.favorite.article) {
      if (state.favorite.article[id] && !state.archive[id])
      favoriteArticlesList.push(state.articles[id]);
    }
    return favoriteArticlesList;
  },
  favoriteInformers(state) {
    return Object.keys(state.favorite.informer)
      .filter(
        (article_id) =>
          (!state.gray[article_id] && !state.archive[article_id]) || false
      )
      .map((article_id) => state.articles[article_id]);
  },
  favoriteContents(state) {
    return Object.keys(state.favorite.content)
      .filter(
        (article_id) =>
          (!state.gray[article_id] && !state.archive[article_id]) || false
      )
      .map((article_id) => state.articles[article_id]);
  },

  section_articles(state, getters, rootState, rootGetters) {
    const lang = rootGetters["config/user/get"]("locale");

    return (section_id) => {
      const section = state.sections[section_id] || helpers.GET_EMPTY_SECTION();
      return Object.keys(section.articles)
        .filter(
          (article_id) => !state.gray[article_id] && !state.archive[article_id]
        )
        .map((article_id) => state.articles[article_id])
        .sort((a, b) => (b.sort - a.sort || a.name.localeCompare(b.name, lang)) );
    };
  },
  section_children(state, getters, rootState, rootGetters) {
    const lang = rootGetters["config/user/get"]("locale");

    return (section_id) => {
      const section = getters.section(section_id);
      const result = Object.keys(section.children).map(
        (child_id) => state.sections[child_id]
      );
      return result.sort((a, b) => (b.sort - a.sort || a.name.localeCompare(b.name, lang)) );
    };
  },
  section_can_be_parent(state, getters) {
    return (section_id) => {
      const section = state.sections[section_id] || helpers.GET_EMPTY_SECTION();
      if (!section.id) return getters.sections;

      return Object.keys(state.sections)
        .filter((section_id) => {
          const current = state.sections[section_id];
          return !(section.lft <= current.lft && section.rgt >= current.rgt);
        })
        .reduce(function (acc, child_id) {
          acc[child_id] = _.cloneDeep(state.sections[child_id]);
          if (parseInt(child_id) === section.parent_id)
            delete acc[child_id].children[section_id];
          return acc;
        }, {});
    };
  },

  initialized(state) {
    return state.initialized;
  },
  unreadArticles: (state) => state.unreadArticles,
  unreadArticlesLoaded: (state) => state.unreadArticlesLoaded,
  numberOfUnreadArticles: (state) => {
    return state.unreadArticles.length;
  },
  favoriteArticlesLoaded: (state) => state.favoriteArticlesLoaded,
};

const mutations = {
  CLEAR_ARTICLE_ADDITIONS(state, data) {
    const article_id = parseInt(data.id);
    const article = state.articles[article_id] || {};
    if (article === {}) return;

    const type = helpers.GET_ARTICLE_TYPE(article);
    Vue.delete(state.type[type], article_id);
    Vue.delete(state.favorite[type], article_id);

    if (type === "article") {
      const section_id = parseInt(article.section_id || 0);
      if (state.sections[section_id] && state.sections[section_id].articles)
        Vue.delete(state.sections[section_id].articles, article_id);
    }
    if (article.status === "archive") Vue.delete(state.archive, article_id);

    if (article.status === "gray") Vue.delete(state.gray, article_id);
  },
  SET_ARTICLE_ADDITIONS(state, { data, from_fav = false }) {
    const article_id = parseInt(data.id);
    const article = state.articles[article_id] || {};
    if (article === {}) return;

    const type = helpers.GET_ARTICLE_TYPE(article);
    const is_archive = article.status === "archive";
    const is_gray = article.status === "gray";
    Vue.set(state.type[type], article_id, true);

    if (article.is_fav) Vue.set(state.favorite[type], article_id, true);
    if (type === "article" && !from_fav) {
      const section_id = parseInt(state.articles[article_id].section_id || 0);
      mutations.INIT_SECTION(state, section_id);
      Vue.set(state.sections[section_id].articles, article_id, is_archive);
    }

    if (is_archive) Vue.set(state.archive, article_id, true);
    if (is_gray) Vue.set(state.gray, article_id, true);
  },
  SET_ARTICLE_DATA(state, { data, full = false, from_fav = false }) {
    if (!data.id) return;
    const article_id = Number(data.id);
    const { is_full = full } = data;
    //if (state.articles[article_id] && !is_full) return;
    data.is_full = is_full;
    data.id = article_id;

    mutations.CLEAR_ARTICLE_ADDITIONS(state, data);
    if (!state.articles[article_id] || is_full) Vue.set(state.articles, article_id, data);
    mutations.SET_ARTICLE_ADDITIONS(state, { data, from_fav });
  },
  UPDATE_ARTICLE_LIKES(state, data){
    const article_id = Number(data.id);
    if(!state.articles[article_id]) return;

    Vue.set(state.articles[article_id], "likes", data.likes);
    Vue.set(state.articles[article_id], "is_liked", data.is_liked);
  },
  INIT_SECTION(state, section_id) {
    const section = state.sections[section_id];
    if (!section)
      Vue.set(state.sections, section_id, helpers.GET_EMPTY_SECTION());
  },
  SET_SECTION_DATA(state, { data, full = false }) {
    const section_id = parseInt(data.id) || 0;
    const { articles = {}, is_full = full } = data;
    const parent_id = data.parent_id || 0;

    const section = state.sections[section_id] || helpers.GET_EMPTY_SECTION();
    if (section.id && section_id !== 0) {
      mutations.INIT_SECTION(state, section.parent_id);
      Vue.delete(state.sections[section.parent_id].children, section_id);
    }

    data.is_full = is_full || full;
    data.id = Number(data.id) || 0;
    data.parent_id = Number(parent_id);
    data.articles = section.articles || {};
    data.children = section.children || {};

    if (section_id === 0) {
      data.lft = -Infinity;
      data.rgt = Infinity;
    }

    if (is_full || !state.sections[section_id]) {
      Vue.set(state.sections, section_id, data);
    } else {
      Object.keys(data).forEach((key) => {
        if (!state.sections[section_id].hasOwnProperty(key))
          Vue.set(state.sections[section_id], key, data[key]);
      });
    }

    if (section_id !== 0) {
      Vue.set(state.favorite.section, section_id, !!data.is_fav);

      mutations.INIT_SECTION(state, parent_id);
      Vue.set(state.sections[parent_id].children, section_id, true);
    }

    Object.keys(articles).forEach((key) => {
      mutations.SET_ARTICLE_DATA(state, { data: articles[key], full: false });
    });
  },
  setLoaded(state, loaded) {
    state.loaded = loaded;
  },
  initialized(state, init) {
    Vue.set(state, "initialized", init);
  },

  setArticleFav(state, { article_id, is_fav }) {
    const article = state.articles[article_id] || {};
    const type = helpers.GET_ARTICLE_TYPE(article);
    Vue.set(article, "is_fav", is_fav);
    Vue.set(state.favorite[type], article_id, is_fav);
  },
  setSectionFav(state, { section_id, is_fav }) {
    const section = state.sections[section_id] || {};
    Vue.set(section, "is_fav", is_fav);
    Vue.set(state.favorite.section, section_id, is_fav);
  },
  deleteArticle(state, article) {
    const article_id = article.id;
    if (!article_id) return;

    mutations.CLEAR_ARTICLE_ADDITIONS(state, article);
    Vue.delete(state.articles, article_id);
  },
  deleteSection(state, data) {
    const section_id = data.id;
    if (!section_id) return;

    const section = state.sections[section_id];
    mutations.INIT_SECTION(state, section.parent_id);
    Vue.delete(state.sections[section.parent_id].children, section_id);
  },
  setArticleComment(state, { comment, articleId }) {
    const article = state.articles[articleId];
    const id = comment.id;

    if (!article.comments) {
      Vue.set(state.articles[articleId], "comments", []);
    } else {
      let newArr = state.articles[articleId].comments;
      newArr.push(comment);
      Vue.set(state.articles[articleId].comments, newArr);
    }
  },
  deleteArticleComment(state, {articleId, commentId}){
    let oldArr = state.articles[articleId].comments;
    let newArr = oldArr.filter(function(comment){
      return comment.id != commentId;
    });
    Vue.set(state.articles[articleId].comments, newArr);
  },
  updateCommentsCount(state, { articleId, isAddition, value = 1 }){
    let newCount = state.articles[articleId].comments_count;
    if(isAddition){
      newCount++;
    } else {
      newCount = newCount - value;
    }
    Vue.set(state.articles[articleId], 'comments_count', newCount);
  },
  setSubscriptionStatus(state, {articleId, status}) {
    const article = state.articles[articleId];
    Vue.set(article, "subscription", status);
  },
  SET_UNREAD_ARTICLES_LIST(state, articles) {
    Vue.set(state, "unreadArticles", articles);
    state.unreadArticlesLoaded = true;
  },
  SET_ARTICLE_READ(state, article_id) {
    const id = Number(article_id);
    if (state.unreadArticles.includes(id)) {
      let article_index = state.unreadArticles.indexOf(id);
      if (article_index > -1) state.unreadArticles.splice(article_index, 1);
    }
  },
  SET_READ_ARTICLES_STATUS(state, articles) {
    state.unreadArticles = state.unreadArticles.filter(
      (id) => !articles.includes(id)
    );
    state.unreadArticlesLoaded = true;
  },
  UPDATE_SECTIONS_SORT(state, sort) {
    sort.forEach((item) => {
      state.sections[item.id].sort = item.sort;
    });
  },
  UPDATE_ARTICLES_SORT(state, sort) {
    sort.forEach((item) => {
      state.articles[item.id].sort = item.sort;
    });
  },
};

export const structure = {
  namespaced: true,
  modules: {
    comments: comments,
  },
  state: () => init_state,
  getters,
  mutations,
  actions: {
    initSnippets({ commit }) {
      const promises = [];

      /* Snippets */
      promises[promises.length] = this.$app.$api.article
        .snippets("informer")
        .then((response) => {
          Object.keys(response.data).forEach((k) => {
            commit("SET_ARTICLE_DATA", { data: response.data[k], full: false });
          });
        });
      promises[promises.length] = this.$app.$api.article
        .snippets("content")
        .then((response) => {
          Object.keys(response.data).forEach((k) => {
            commit("SET_ARTICLE_DATA", { data: response.data[k], full: false });
          });
        });
      return Promise.all(promises);
    },
    init({ state, commit, dispatch }, { force = false } = {}) {
      if (!force && state.initialized) {
        return state.initialized === true ? Promise.all([]) : state.initialized;
      }

      const promises = [];
      /* Standard articles */
      promises[promises.length] = this.$app.$api.tree
        .full()
        .then((response) => {
          dispatch("initTree", response.data);
        });

      /* Finally */
      const promise = Promise.all(promises);
      commit("initialized", promise);
      return promise.finally(() => {
        commit("initialized", true);
      });
    },
    initTree({ state, commit }, tree) {
      if (tree && typeof tree === "object") {
        Object.keys(tree).forEach((k) => {
          const node = tree[k];

          if (node.type === "section") {
            commit("SET_SECTION_DATA", {data: node, full: false});
          }
          else commit("SET_ARTICLE_DATA", { data: node, full: false });
        });
      }

      commit("setLoaded", true);
    },
    async saveSection({ state, commit }, { section }) {
      if (section.parent_id === 0) section.parent_id = null;

      const response = await this.$app.$api.section.save(section);
      commit("SET_SECTION_DATA", { data: response.data.data, full: true });

      return response.data.data;
    },

    saveArticle({ commit, dispatch, state }, { article }) {
      const articleLoad = article;
      if (articleLoad.type === "article" || !articleLoad.type)
        articleLoad.type = "";
      if (articleLoad.section_id === 0) articleLoad.section_id = null;
      if (articleLoad.date_open) {
        articleLoad.date_open = moment(articleLoad.date_open)
          .startOf("day")
          .format("YYYY-MM-DD");
      }
      if (articleLoad.date_close) {
        articleLoad.date_close = moment(articleLoad.date_close)
          .endOf("day")
          .format("YYYY-MM-DD");
      }
      const formData = new FormData();
      Object.keys(articleLoad).forEach((k) => {
        Array.isArray(articleLoad[k])
          ? articleLoad[k].forEach((v) => formData.append(k + "[]", v))
          : formData.append(k, articleLoad[k]);
      });

      let request;
      if (articleLoad.id) {
        formData.append("_method", "PUT");
        request = this.$app.$api.article.update(articleLoad.id, formData);
      } else {
        request = this.$app.$api.article.create(formData);
      }

      return request.then((response) => {
        const articleNew = response.data.data;
        if (
          new Date(articleNew.date_open) <= new Date() &&
          new Date(articleNew.date_close) >= new Date()
        )
          commit("SET_ARTICLE_DATA", { data: articleNew, full: true });
        else commit("deleteArticle", response.data.data);

        const full_type = helpers.GET_ARTICLE_TYPE(articleNew, true);
        LocalStorage.remove(
          articleLoad.id || "0",
          articleLoad.workspace,
          "article"
        );
        this.$app.$router.push({
          name: full_type + ".view",
          params: { id: articleNew.id },
        });
        return articleNew;
      });
    },
    setFavorite({ commit, state }, { entityType, entityId, status }) {
      let request;
      if (status) {
        request = this.$app.$api.favorite.set(entityType, entityId);
      } else {
        request = this.$app.$api.favorite.remove(entityType, entityId);
      }
      request.then(() => {
        if (entityType === "article") {
          commit("setArticleFav", { article_id: entityId, is_fav: status });
          return true;
        }
        if (entityType === "section") {
          commit("setSectionFav", { section_id: entityId, is_fav: status });
          return true;
        }
      });
      return request;
    },
    loadArticle({ commit, getters, dispatch }, { id, articleData, query }) {
      if (!id) return { loaded: false };

      let article = articleData;
      if (article && article.is_full) {
        commit("SET_ARTICLE_DATA", { data: article, full: true });
        dispatch("comments/init", {commentsList: article.comments, articleId: article.id});
        return { loaded: true };
      } else {
        let request;
        if (query) {
          request = this.$app.$api.article.getWithHighlight(id, query);
        } else {
          if (getters.article(id) && getters.article(id).is_full){
            return { loaded: true };
          }
          request = this.$app.$api.article.get(id);
        }
        request.then((response) => {
          article = response.data.data;
          commit("SET_ARTICLE_DATA", { data: article, full: true });
          dispatch("comments/init", {commentsList: article.comments, articleId: article.id});
          commit('main_page/ADD_ARTICLE_TO_LAST_SEEN', article, { root: true });
        });
        return request;
      }
    },
    loadSection({ commit, dispatch, state }, { id, query }) {
      let section = state.sections[id] || {};
      if (section.is_full === true && !query) {
        return new Promise((resolve) => {
          resolve({ loaded: false });
        });
      } else {
        let request;
        if (query) {
          request = this.$app.$api.section.getWithHighlight(id, query);
        } else {
          request =  this.$app.$api.section.get(id);
        }
        request.then(({ data }) => {
            section = data;
            let children = data.children || [];
            commit("SET_SECTION_DATA", { data: section, full: true });
            children.forEach((child) => {
              commit("SET_SECTION_DATA", { data: child, full: false });
            });
          })
          .then(() => {
            return { loaded: false };
          });
        return request;
      }
    },
    loadSectionArticles({ commit, dispatch, state }, { id, paginateData }) {
      return new Promise((resolve, reject) => {
        this.$app.$api.section.articles(id, paginateData)
            .then(response => {
              response.data.data.forEach(article => {
                commit("SET_ARTICLE_DATA", { data: article, full: false });
              });
              resolve(response.data);
            });
      });
    },
    loadSectionTree(context, data) {
      return new Promise((resolve, reject) => {
        this.$app.$api.kb_menu
            .loadChildrenOptions(data)
            .then(response => {
              resolve(response.data);
            })
            .catch(() => {
              resolve(null);
            });
      });
    },
    deleteArticle({ state, commit, dispatch }, { articleId, deleted }) {
      const article = state.articles[articleId];
      let request;
      if (article) {
        if (article.status === "archive") {
          request = this.$app.$api.article.delete(articleId);
          request.then(() => {
            dispatch("removeOptionFromMenu", article);
            commit("deleteArticle", article);
          });
        }
        if (article.status !== "archive") {
          if (article.is_fav === true)
            commit("setArticleFav", {article_id: articleId, is_fav: false});
          if (deleted) {
            dispatch("removeOptionFromMenu", article);
            commit("deleteArticle", article);
          } else if (!deleted) {
            request = this.$app.$api.article.delete(articleId);
            request.then((response) => {
              dispatch("removeOptionFromMenu", article);
              commit("deleteArticle", article);
            });
          }
        }
      }
      return request;
    },
    deleteSection({ state, commit, dispatch }, { sectionId, deleted }) {
      const section = state.sections[sectionId];
      if (section && section.is_fav === true)
        commit("setSectionFav", { section_id: section.id, is_fav: false });
      let request;


      if (deleted && section) {
        if (section.articles) {
          Object.keys(section.articles).forEach(function (c) {
            dispatch("deleteArticle", {
              articleId: section.articles[c].id,
              deleted: true,
            });
          });
        }
        dispatch("removeOptionFromMenu", section);
        commit("deleteSection", section);
        if (section.children) {
          Object.keys(section.children).forEach(function (child_id) {
            dispatch("deleteSection", {
              sectionId: child_id,
              deleted: true,
            });
          });
        }
      } else if (!deleted) {
        request = this.$app.$api.section.delete(sectionId);
        request.then(() => {
          if (section) {
            if (section.articles) {
              Object.keys(section.articles).forEach(function (child_id) {
                dispatch("deleteArticle", {
                  articleId: child_id,
                  deleted: true,
                });
              });
            }

            dispatch("removeOptionFromMenu", section);
            commit("deleteSection", section);
            if (section.children) {
              Object.keys(section.children).forEach(function (child_id) {
                dispatch("deleteSection", {
                  sectionId: child_id,
                  deleted: true,
                });
              });
            }
          }
          commit("main_page/SET_TOP_SECTIONS_LOADED", false, { root: true });
          commit("main_page/SET_TOP_SECTIONS", [], { root: true });
          dispatch("main_page/loadTopSections", null, { root: true });
        });
      }

      return request;
    },
    removeOptionFromMenu({ rootGetters, dispatch }, option) {
      let parent_key = "section_id";
      let code_prefix = "art";
      if (option.type === "section") {
        parent_key = "parent_id";
        code_prefix = "sec";
      }
      let optionCode = Object.keys(rootGetters["menu/list"]).find((code) => {
        let arr = code.split(".").reverse();
        return arr[0] === code_prefix + option.id;
      });
      let parentCode = "aside.sections";
      if (option[parent_key]) {
        parentCode = Object.keys(rootGetters["menu/list"]).find((code) => {
          let arr = code.split(".").reverse();
          return arr[0] === "sec" + option[parent_key];
        });
      }
      dispatch("menu/removeOption", { optionCode, parentCode }, { root: true });
    },
    async loadHistory(state, id) {
      return await this.$app.$api.article.getHistory(id);
    },
    async restoreArticle({ commit, state }, { id, restoreId }) {
      const response = await this.$app.$api.article.restoreVersion(
        id,
        restoreId
      );
      const article = response.data.data;
      commit("SET_ARTICLE_DATA", { data: article, full: true });
      return response;
    },
    favoriteGroup({ commit, state }, { articles, sections, favourite }) {
      const request = this.$app.$api.mass.favourite({
        articles,
        sections,
        favourite,
      });
      request.then(() => {
        articles.forEach((article_id) => {
          commit("setArticleFav", { article_id, is_fav: favourite });
        });
        sections.forEach((section_id) => {
          commit("setSectionFav", { section_id, is_fav: favourite });
        });
      });
      return request;
    },
    moveGroup({ commit, state }, { articles, sections, target }) {
      const request = this.$app.$api.mass.move({ articles, sections, target });
      request.then(() => {
        articles.forEach((article_id) => {
          const article = Object.assign({}, state.articles[article_id]);
          article.is_full = true;
          article.section_id = target;
          commit("SET_ARTICLE_DATA", { data: article, full: true });
        });
        sections.forEach((section_id) => {
          const section = Object.assign({}, state.sections[section_id]);
          section.parent_id = target;
          section.is_full = true;
          commit("SET_SECTION_DATA", { data: section, full: true });
        });
      });
      return request;
    },
    deleteGroup({ commit, state }, { articles, sections }) {
      const request = this.$app.$api.mass.destroy({ articles, sections });
      request.then(() => {
        articles.forEach((article_id) => {
          commit("deleteArticle", state.articles[article_id]);
        });
        sections.forEach((section_id) => {
          commit("deleteSection", state.sections[section_id]);
        });
      });
      return request;
    },
    loadUnreadArticles({ commit }) {
      const request = this.$app.$api.article.unread.load();
      request.then((response) => {
        commit("SET_UNREAD_ARTICLES_LIST", response.data);
      });
      return request;
    },
    setArticlesRead({ state, commit }, arrayOfIds) {
      const request = this.$app.$api.article.read.setRead({
        articles: arrayOfIds,
      });
      request.then(() => {
        commit("SET_READ_ARTICLES_STATUS", arrayOfIds);
      });
      return request;
    },
    sortingUpdate({ commit }, { items, type }) {
      const request = this.$app.$api.sorting[type]({ items });
      request.then(() => {
        if (type === "sections") commit("UPDATE_SECTIONS_SORT", items);
        else commit("UPDATE_ARTICLES_SORT", items);
      });
      return request;
    },
    loadFavorite({ state, commit }) {
      const request = this.$app.$api.favorite.list();
      request.then((response) => {
        response.data.articles.forEach((article) => {
          if (!state.articles[article["id"]])
            commit("SET_ARTICLE_DATA", { data: article, full: false, from_fav: true });
          commit("setArticleFav", { article_id: article["id"], is_fav: true });
        });
        response.data.sections.forEach((section) => {
          if (!state.sections[section["id"]])
            commit("SET_SECTION_DATA", { data: section, full: false });
          commit("setSectionFav", { section_id: section["id"], is_fav: true });
        });
      }).finally(() => {
        state.favoriteArticlesLoaded = true;
    });
      return request;
    },
    loadArchive({ commit }) {
      const request = this.$app.$api.article.archive();
      request.then((response) => {
            const archives = response.data;
            archives.forEach((archive) => {
              commit("SET_ARTICLE_DATA", { data: archive, full: false });
            });
          });
      return request;
    },
    loadGrays({ commit }) {
      const request = this.$app.$api.article.grays();
      request.then((response) => {
        const grays = response.data;
        grays.forEach((gray) => {
          commit("SET_ARTICLE_DATA", { data: gray, full: false });
        });
      });
      return request;
    },
    loadMissingArticles({ commit }, arrayOfIds) {
      let ids = { articles_ids: arrayOfIds };
      this.$app.$api.article.articles(ids).then((response) => {
        Object.values(response.data).forEach((article) => {
          article.type = "article";
          commit("SET_ARTICLE_DATA", { data: article, full: false });
        });
      });
    },
    setArticleReaction({ commit }, { id, hasLike }) {
      let request;
      if (hasLike) {
        request = this.$app.$api.article.unLikeArticle(id);
      } else {
        request = this.$app.$api.article.likeArticle(id);
      }
      request.then((response) => {
        commit("UPDATE_ARTICLE_LIKES", response.data);
      });
      return request;
    },
    setArticleSubscription({ commit }, data) {
      return new Promise((resolve, reject) => {
        this.$app.$api.article
            .setSubscription(data)
            .then(response => {
              commit("setSubscriptionStatus", {articleId: data.article_id, status: response.data.subscription });
              resolve(response.data);
            });
      });
    }
  },
};
export default structure;
